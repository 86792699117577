import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import {
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutGridSpan,
} from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { ThirdPartyPackageWizardState } from "src/stores/wizard/state";
import { useLocalization } from "@shared-ui/localization-context";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSFakeInput, EGDSSelect } from "@egds/react-core/input";
import { EGDSSheetTransition } from "@egds/react-core/sheet";
import { withStores } from "src/stores";
import { LOCATION_TYPES, Locations3PPDialog, LocationsListOption } from "./Locations3PPDialog";

export interface Locations3PPProps {
  colSpan?: EGDSLayoutConditionalGridSpan;
  lobState: ThirdPartyPackageWizardState;
  context: ExtendedContextStore;
}

const buildLocationParams = (
  lobState: ThirdPartyPackageWizardState,
  context: ExtendedContextStore | undefined
): { name: string; value: string | undefined }[] => {
  const { destination, origin } = lobState;

  const siteid = context?.site.id;
  const locale = context?.locale || "";
  const currency = context?.currency;

  const splittedDestinationValue = destination.split(":");
  const destinationValue = splittedDestinationValue[0];
  const destinationCityName = splittedDestinationValue[1];

  const isApiCallEnabled = siteid && lobState.config.siteIdsWithApiCall.includes(siteid);
  const params = [];
  const posNoLangParam = [4, 6, 10122006, 100388];

  if (isApiCallEnabled) {
    return [
      { name: "origin", value: origin },
      { name: "destination", value: destination },
    ];
  }

  if (siteid && !posNoLangParam.includes(siteid)) {
    if (siteid === 70472 && locale.replace("_", "-").toLowerCase() === "en-gb") {
      params.push({ name: "lang", value: "de-CH" });
    } else {
      params.push({ name: "lang", value: locale.replace("_", "-") });
    }
  }

  if (siteid === 6 && destinationValue !== "0") {
    if (destinationCityName) {
      params.push({ name: "rgid", value: destinationValue }, { name: "rid", value: destinationCityName });
    } else {
      params.push({ name: "udf", value: destinationValue });
    }
  } else {
    if (destinationValue && destinationValue !== "any") {
      params.push({ name: "rgid", value: destinationValue });
    }
    if (destinationCityName) {
      params.push({ name: "rid", value: destinationCityName });
    }

    if (siteid === 70472) {
      params.push({ name: "currency", value: "EUR" });
    } else {
      params.push({ name: "currency", value: currency });
    }
  }

  if (origin && origin !== "any") {
    params.push({ name: "depap", value: origin });
  }

  return params;
};

export const Locations3PPComponent = observer((props: Locations3PPProps) => {
  const { formatText } = useLocalization();
  const { lobState, context, colSpan } = props;

  const locationColSpan = {
    small: 1 as EGDSLayoutGridSpan,
    medium: 1 as EGDSLayoutGridSpan,
  };

  const leavingKeyHelper = new ItemKeyHelper("leaving-options");
  const goingKeyHelper = new ItemKeyHelper("going-options");
  const inputKeyHelper = new ItemKeyHelper("input-options");

  /**
   * Handle selection of origin in medium and greater viewport sizes
   */
  const changeOrigin = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    lobState.setOrigin(selectEvent.target.value);
  };

  /**
   * Handle selection of destination in medium and greater viewport sizes
   */
  const changeDestination = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    lobState.setDestination(selectEvent.target.value);
  };

  const { destinations, origins, originInvalidKey, destinationInvalidKey } = lobState;

  const params = buildLocationParams(lobState, context);

  const leavingPlaceholder = formatText(lobState.config.origin.placeholderText);
  const goingPlaceholder = formatText(lobState.config.destination.placeholderText);

  const originDialogQueryRouteId = "threepp-origin-dialog";
  const destinationDialogQueryRouteId = "threepp-destination-dialog";
  const [originName, setOriginName] = React.useState("");
  const [destinationName, setDestinationName] = React.useState("");
  const [isLocationOriginDialogOpen, originDialogActions, Location3PPOriginDialogComponent] = useDialog(
    originDialogQueryRouteId
  );
  const [isLocationDestinationDialogOpen, destinationDialogActions, Location3PPDestinationDialogComponent] = useDialog(
    destinationDialogQueryRouteId
  );

  const onOriginSelect = ({ code, name }: LocationsListOption) => {
    lobState.setOrigin(code, false);
    lobState.setDestination("");

    setOriginName(name);
    setDestinationName("");
    originDialogActions.closeDialog();
  };

  const onDestinationSelect = ({ code, name }: LocationsListOption) => {
    lobState.setDestination(code);

    setDestinationName(name);
    destinationDialogActions.closeDialog();
  };

  return (
    <>
      {params &&
        params.map(({ name, value }) => <input type="hidden" key={inputKeyHelper.next()} name={name} value={value} />)}
      <EGDSLayoutGridItem colSpan={colSpan}>
        <EGDSLayoutGrid columns={{ small: 1, medium: 2 }} space="three" className="Locations3PP">
          <Viewport>
            {/* Origin and destination selector type dialog */}
            <ViewSmall>
              <>
                <EGDSLayoutGridItem colSpan={locationColSpan}>
                  <div>
                    <EGDSFakeInput
                      id={`${originDialogQueryRouteId}-trigger`}
                      className={`${originDialogQueryRouteId}-trigger`}
                      label={leavingPlaceholder}
                      placeholder={leavingPlaceholder}
                      value={originName}
                      onClick={originDialogActions.openDialog as any}
                      icon="place"
                    />
                    <EGDSSheetTransition isVisible={isLocationOriginDialogOpen}>
                      <Location3PPOriginDialogComponent>
                        <Locations3PPDialog
                          queryRouteId={originDialogQueryRouteId}
                          locationType={LOCATION_TYPES.origin}
                          toolbarHeader={leavingPlaceholder}
                          options={origins}
                          onDismiss={originDialogActions.closeDialog}
                          onDoneButtonClick={onOriginSelect}
                        />
                      </Location3PPOriginDialogComponent>
                    </EGDSSheetTransition>
                  </div>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={locationColSpan}>
                  <div>
                    <EGDSFakeInput
                      id={`${destinationDialogQueryRouteId}-trigger`}
                      className={`${destinationDialogQueryRouteId}-trigger`}
                      label={goingPlaceholder}
                      placeholder={goingPlaceholder}
                      value={destinationName}
                      onClick={destinationDialogActions.openDialog as any}
                      icon="place"
                    />
                    {destinations && (
                      <EGDSSheetTransition isVisible={isLocationDestinationDialogOpen}>
                        <Location3PPDestinationDialogComponent>
                          <Locations3PPDialog
                            onDismiss={destinationDialogActions.closeDialog}
                            onDoneButtonClick={onDestinationSelect}
                            queryRouteId={destinationDialogQueryRouteId}
                            locationType={LOCATION_TYPES.destination}
                            options={destinations}
                            toolbarHeader={goingPlaceholder}
                          />
                        </Location3PPDestinationDialogComponent>
                      </EGDSSheetTransition>
                    )}
                  </div>
                </EGDSLayoutGridItem>
              </>
            </ViewSmall>

            {/* Origin and destination selector type simple select */}
            <ViewMedium>
              <>
                <EGDSLayoutGridItem colSpan={locationColSpan}>
                  <EGDSSelect
                    icon="place"
                    id="leaving-3pp"
                    label={leavingPlaceholder}
                    placeholder={leavingPlaceholder}
                    onChange={changeOrigin}
                    invalid={formatText(originInvalidKey)}
                    value={lobState.origin || undefined}
                  >
                    {origins.map(({ code, name }) => {
                      return (
                        <option value={code} key={leavingKeyHelper.next()}>
                          {name}
                        </option>
                      );
                    })}
                  </EGDSSelect>
                </EGDSLayoutGridItem>

                <EGDSLayoutGridItem colSpan={locationColSpan}>
                  <EGDSSelect
                    icon="place"
                    id="going-3pp"
                    label={goingPlaceholder}
                    placeholder={goingPlaceholder}
                    onChange={changeDestination}
                    invalid={formatText(destinationInvalidKey)}
                    value={lobState.destination || undefined}
                  >
                    {destinations &&
                      destinations.map(({ code, name }) => {
                        return (
                          <option value={code} key={goingKeyHelper.next()}>
                            {name}
                          </option>
                        );
                      })}
                  </EGDSSelect>
                </EGDSLayoutGridItem>
              </>
            </ViewMedium>
          </Viewport>
        </EGDSLayoutGrid>
      </EGDSLayoutGridItem>
    </>
  );
});

export const Locations3PP = withStores("context")(Locations3PPComponent);
